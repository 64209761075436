import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  readonly apiAssembler = `https://template-assembler-npifkwbvzq-uc.a.run.app`
  affiliateId: string | null = null;
  affiliateCampaign = 'starter';
  tagLogs: any[] = [];
  uid: any = null;
  userObject: any = null;
  gtmIsLoaded = false;

  adIdInFunnel: string | null = null;

  pages: any[] = [];

  constructor(public router: Router,
              public activatedRoute: ActivatedRoute,
              public titleService: Title,
              public httpClient: HttpClient,
              public afs: AngularFirestore,
              public analytics: AngularFireAnalytics,
              public afAuth: AngularFireAuth) {
    // DETEREMINE IF USER IS LOGGED IN
    this.afAuth.user.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        this.getUserObject();
        this.log('user loggedin thus uid');
      } else {
        this.activatedRoute.queryParams.subscribe(params => {
          if (params.uid) {
            this.uid = params.uid;
            this.log('user assigned by uid param');
          }
          if (params.email) {
            let sub = this.afs.collection('users', ref => ref.where('email', '==', params.email)).valueChanges().subscribe((users: any[]) => {
              sub.unsubscribe();
              if (users.length) {
                this.uid = users[0].uid
                this.log('user found and assigned from email param');
              }
            })
          }
          if (params.adid) {
            this.adIdInFunnel = params.adid;
          }
        })
      }
    })

    // PUSH THE PAGE ONLOAD TO THE DATALAYER
    setTimeout(() => {
      this.sendPageEvent(false);
    }, 1000);

    // SUBSCRIBE TO PUSH PAGE CHANGES TO DATALAYER
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.sendPageEvent(true);
        }, 1000);
      }
    });

    // SUBSCRIBE TO SET THE TITLE BASED ON ROUTING 
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    ).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`${title}`);
      }
    });

    // SUBSCRIBE TO THE AFFILIATE ID AND CAMPAIGN
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.utm_source && params.utm_medium === 'affiliate') {
        this.affiliateId = params.utm_source;
        this.affiliateCampaign = params.utm_campaign;
      }
    });

    // LISTED FOR ONBEFORE UNLOAD
    globalThis.onbeforeunload = () => {
      this.log('unload')
    }
  }

  getUserObject() {
    if (this.uid) {
      this.afs.doc('users/' + this.uid).valueChanges().subscribe(user => {
        this.userObject = user;
      });
    }
  }

  // INITIALIZE GTM TAG
  loadGtm(gtmId: string, funnelId: string, aid: string|null): void {
    if (this.gtmIsLoaded) return;
    this.gtmIsLoaded = true;
    const id = gtmId || this.gtmIsLoaded;
    if (!id) {
      return;
    }

    // Add GTM script to the head
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');`;
    document.head.insertBefore(script, document.head.firstChild);

    // Add GTM noscript to the body
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);

    // console.log('added gtm script for ' + id);

    this.afs.collection('users/' + aid + '/landings/', ref=> ref.where('funnelId', '==', funnelId)).valueChanges().subscribe((pages: any) => {
      this.pages = pages;
    });
  }

  // LOG TO DATABASE ON KNOWN USER
  log(description: string) {
    if (this.uid) {
      this.afs.collection('users/' + this.uid + '/logs').add({
        route: this.router.url,
        time: new Date(),
        description: description ? description : ''
      })
    }
  }

  // PAGE EVENT LOGGING
  sendPageEvent(engagePageCheck: boolean) {
    let title = this.titleService.getTitle()
    if (engagePageCheck) {
      if (!this.pages.find(p => p.name === title)) { 
        return
      }
    }
    if (this.userObject) {
      if (this.userObject.axipAgency) {
        return
      }
    }
    try {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        event: 'page_view',
        page_location: window.location.href, 
        page_path: this.router.url, 
        page_title: title, 
      });
    } catch (err) {
      console.error('Error sending page event: ', err);
    }
    this.logDataLayer();
  }
  // EVENT LOGGING FUNCTION
  sendEvent(data: any, name: string): void {
    const gtmTag: any = {
      event: name,
      ...data,
    };
    // this.gtm.pushTag(gtmTag);
    gtmTag.time = new Date();
    this.tagLogs.push(gtmTag);
  }

  // LOG DATALAYER FOR AUDIT FUNCTION
  logDataLayer() {
    const win = window as any;
    if (win && win['dataLayer']) {
      // console.log('DataLayer: ', win['dataLayer']);
    }
  }


  returnExtraTemplates(templates: any[]) {
    let extraTemplates = [];
    for (let template of templates) {
      if (template.alternateCurrencies) {
        for (let currency of template.alternateCurrencies) {
          let dupe = _.cloneDeep(template);
          dupe.currency = currency.currency;
          dupe.buildPrice = currency.buildPrice;
          dupe.subscriptionPrice = currency.subscriptionPrice;
          dupe.subscriptionInterval = currency.subscriptionInterval;
          dupe.taxPercentage = currency.taxPercentage;
          dupe.taxName = currency.taxName;
          dupe.availableDiscounts = currency.availableDiscounts ? currency.availableDiscounts : [];
          dupe.id = template.id + '-' + currency.id;
          if (extraTemplates.find(t => t.id === dupe.id) == null) {
            extraTemplates.push(dupe);
          }
        }
      }
    }
    return extraTemplates;
  }

  // START BUILD FUNCTION (IN DUMB SPOT)
  startBuild(uid: string) {
    return this.httpClient.post(`${this.apiAssembler}/v1/handle_new`, {
      uid: uid
    })
  }
  buildCustom(uid: string, command: string) {
    return this.httpClient.post(`${this.apiAssembler}/v1/create_runner`, {
      uid: uid,
      commandType: 'automate',
      command: command,
    })
  }

}
